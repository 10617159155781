/* eslint-disable no-underscore-dangle */
/* eslint-disable no-alert */
import '@fontsource/roboto-condensed/latin-400.css';
import '@fontsource/roboto-condensed/latin-700.css';

import './src/styles/global.styl';
import './src/fonts/style.styl';

export const onServiceWorkerUpdateFound = () => {
    const answer = window.confirm(
        `Une nouvelle version de l'application est disponible (actuelle : ${process.env.__VERSION__})` +
            `Relancer pour voir la dernière version ?`,
    );
    if (answer === true) {
        window.location.reload();
    }
};

export const onServiceWorkerUpdateReady = (
    apiCallbackContext,
    pluginOptions,
) => {
    console.log('yeah Service Worker Updated ! ');

    console.log('pluginOptions', pluginOptions);
    // const isBrowser = () => typeof window !== 'undefined';
};

export const onServiceWorkerInstalled = (apiCallbackContext, pluginOptions) => {
    console.log('yeah Service Worker Installed ! ');

    console.log('pluginOptions', pluginOptions);
};

// export const onClientEntry = async () => {
//     // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//     if (typeof window.IntersectionObserver === `undefined`) {
//         await import(`intersection-observer`);
//         console.log(`# IntersectionObserver is polyfilled!`);
//     }
// };
