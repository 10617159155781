module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"l":"(max-width: 1536px)","md":"(max-width: 1024px)","sm":"(max-width: 720px)","xs":"(max-width: 320px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"config/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#171F2C","cache_busting_mode":"none","description":"Brico Dépot - Menuiserie","display":"standalone","icon":"src/contents/images/app/logo-512.png","lang":"fr","name":"Brico Dépot - Menuiserie","short_name":"Brico Dépot - Menuiserie","start_url":"/","theme_color":"#171F2C","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/window-type/**/*"],"workboxConfig":{"globPatterns":["**/*.{jpg,jpeg,png,svg,webp,ttf,woff,woff2,mp4}"],"maximumFileSizeToCacheInBytes":100000000,"runtimeCaching":[{"handler":"CacheFirst","urlPattern":{}}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
