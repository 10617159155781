// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-views-templates-window-accessories-template-jsx": () => import("./../../../src/views/templates/WindowAccessoriesTemplate.jsx" /* webpackChunkName: "component---src-views-templates-window-accessories-template-jsx" */),
  "component---src-views-templates-window-prices-template-jsx": () => import("./../../../src/views/templates/WindowPricesTemplate.jsx" /* webpackChunkName: "component---src-views-templates-window-prices-template-jsx" */),
  "component---src-views-templates-window-type-template-jsx": () => import("./../../../src/views/templates/WindowTypeTemplate.jsx" /* webpackChunkName: "component---src-views-templates-window-type-template-jsx" */),
  "component---src-views-templates-windows-template-jsx": () => import("./../../../src/views/templates/WindowsTemplate.jsx" /* webpackChunkName: "component---src-views-templates-windows-template-jsx" */)
}

